import NextLink from 'next/link';

import { Button, Container, Link, SectionText } from '@/atoms';
import { Card, SwiperCustom } from '@/molecules';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeAboutUsProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

/**
 * AboutUs
 */
export const AboutUs: FunctionComponent<TypeAboutUsProps> = ({
  className = '',
  backgroundColorBottom,
  backgroundColorBottomMobile,
  backgroundColorTop,
  backgroundColorTopMobile,
  caption,
  cards,
  cutLevel = 50,
  cutLevelMobile = 50,
  desc,
  textColor,
  title,
  subtitle,
  link,
  button,
  index,
  id,
}: TypeAboutUsProps) => (
  <Container
    index={index}
    backgroundColorBottom={backgroundColorBottom}
    backgroundColorTopMobile={backgroundColorTopMobile}
    backgroundColorTop={backgroundColorTop}
    backgroundColorBottomMobile={backgroundColorBottomMobile}
    cutLevel={cutLevel}
    cutLevelMobile={cutLevelMobile}
    className={`flex flex-col gap-10 ${className}`}
    id={id}
  >
    <SectionText
      title={title}
      subtitle={subtitle}
      desc={desc}
      direction="row"
      textAlign="start"
      variant={textColor}
      caption={caption}
      showSeeMore
    />
    <div>
      <SwiperCustom
        navigation={false}
        scrollbar
        gap={30}
        slidesPerView={3}
        slidesPerViewMobile={1.4}
      >
        {cards.map((card, index: number) => (
          <Card
            className={`${VARIANT_MAPS[textColor]} text-heading text-heading--h4 h-full`}
            key={index}
            multimediaProps={{ image: card.image }}
          >
            {card.title}
          </Card>
        ))}
      </SwiperCustom>
    </div>

    <>
      {link?.src && link?.label && (
        <NextLink prefetch={false} passHref href={link.src}>
          <Link
            className="self-center md:self-end"
            arrow
            variant={link.variant}
          >
            {link.label}
          </Link>
        </NextLink>
      )}
    </>
    <>
      {button?.src && button?.label && (
        <Button
          className="self-center md:self-end"
          as="a"
          href={button.src}
          size="large"
          variant={button.variant}
        >
          {button.label}
        </Button>
      )}
    </>
  </Container>
);

AboutUs.displayName = 'AboutUs';
